<template>
  <div>
    <a-modal
      :visible="isModalDetailAssignmentVisible"
      title="Assignment Details"
      centered
      @cancel="handleIsModalDetailAssignmentVisible"
    >
      <detail-assignment :assignment="header.tugas_detail" />
      <template slot="footer">
        <a-button class="d-none" key="back"></a-button>
        <a-button class="d-none" key="submit"></a-button>
      </template>
    </a-modal>
    <a-drawer
      class="drawer-grading"
      placement="left"
      :closable="true"
      :visible="isDrawerDetailStudentVisible"
      @close="handleIsDrawerDetailStudentVisible"
      title="Assignment Result"
      width="40%"
    >
      <!-- <p>{{ dataDrawer }}</p> -->
      <p>Name : {{ dataDrawer.nama ? dataDrawer.nama : "-" }}</p>
      <a-divider />
      <p>
        Submit Status :
        {{
          dataDrawer.submit_time
            ? moment(dataDrawer.submit_time).format("DD MMMM YYYY, HH:mm")
            : ""
        }}
      </p>
      <a-divider />
      <p>Feedback : {{ dataDrawer.feedback ? dataDrawer.feedback : "-" }}</p>
      <a-divider />
      <p>
        Grade :
        <grade-color
          v-if="dataDrawer.tugas_nilai"
          :grade="Number(dataDrawer.tugas_nilai)"
        />
        <span v-else>-</span>
      </p>
      <a-divider />
      <p class="mb-3 font-weight-bold text-dark">Assignment Answer :</p>
      <template v-if="dataDrawer.jawaban_murid">
        <div
          v-if="header.tipe_assignment === 'File Upload'"
          class="d-flex align-items-center"
        >
          <p class="w-50">
            {{ dataDrawer.jawaban_murid | filename }}
          </p>
          <a-button
            @click="downloadFile(dataDrawer.jawaban_murid)"
            type="primary"
            shape="round"
            icon="download"
            class="ml-auto"
            >DOWNLOAD</a-button
          >
        </div>
        <div v-else-if="header.tipe_assignment === 'Essay'">
          <span ref="jawaban" v-html="dataDrawer.jawaban_murid"></span>
        </div>
        <div v-if="header.tipe_assignment === 'Multiple Choice'">
          <div class="d-flex justify-content-between align-items-center mb-4">
            <div>
              <p>Questions : {{ header.tugas_detail.daftar_soal.length }}</p>
            </div>
            <div>
              <p>
                Correct :
                {{
                  cekAnswer(
                    header.tugas_detail.daftar_soal,
                    dataDrawer.jawaban_murid
                  ).correct
                }}
              </p>
            </div>
            <div>
              <p>
                Incorrect :
                {{
                  cekAnswer(
                    header.tugas_detail.daftar_soal,
                    dataDrawer.jawaban_murid
                  ).inCorrect
                }}
              </p>
            </div>
            <div>
              <p>
                Not Answered :
                {{
                  cekAnswer(
                    header.tugas_detail.daftar_soal,
                    dataDrawer.jawaban_murid
                  ).notAnswer
                }}
              </p>
            </div>
          </div>
          <detail-assignment
            :assignment="header.tugas_detail"
            :isStudentChoice="true"
            :studentAnswer="JSON.parse(dataDrawer.jawaban_murid)"
          />
        </div>
      </template>
      <template v-else>Student has not answered</template>
    </a-drawer>
    <loading-state v-if="isLoadingPage" />
    <main-card v-else>
      <span slot="title1">{{ header.kelas ? header.kelas : "-" }}</span>
      <span slot="title2">{{
        header.mata_pelajaran ? header.mata_pelajaran : "-"
      }}</span>
      <span slot="sub-title1"
        >Week {{ header.week ? header.week : "-" }}/{{
          header.total_week ? header.total_week : "-"
        }}</span
      >
      <span slot="sub-title2">{{ header.topik ? header.topik : "-" }}</span>
      <span slot="sub-title2">
        | {{ header.no_kd ? "KD-" + header.no_kd : "-" }}</span
      >
      <div slot="content">
        <a-row type="flex" justify="space-between" class="mb-5">
          <a-col :xs="24" :md="12" :lg="6">
            <h3 class="type">Type</h3>
            <div class="d-flex mt-2 align-items-end">
              <div>
                <p class="value text-primary mr-2">
                  <a-icon
                    v-if="header.tipe_assignment"
                    :type="checkIconAssignmentType(header.tipe_assignment)"
                    class="mr-2"
                  />
                  {{ header.tipe_assignment ? header.tipe_assignment : "-" }}
                </p>
              </div>
              <div>
                <a-button
                  v-if="header.tipe_assignment"
                  @click.prevent="handleIsModalDetailAssignmentVisible"
                  shape="round"
                  size="small"
                  icon="eye"
                ></a-button>
              </div>
            </div>
          </a-col>
          <a-col :xs="24" :md="12" :lg="6">
            <h3 class="type">Due Date</h3>
            <p class="text-dark mt-2">
              {{
                header.tugas_deadline
                  ? moment(header.tugas_deadline).format("DD MMMM YYYY, HH:mm")
                  : "-"
              }}
            </p>
          </a-col>
          <a-col :xs="24" :md="12" :lg="6" class="mt-4 mt-lg-0">
            <h3 class="type">Waiting to Submit</h3>
            <p class="text-dark mt-2">
              <span class="font-weight-bold text-danger">{{
                statistic.waiting_submit
              }}</span>
              /
              {{ statistic.total_student }}
              Student
            </p>
          </a-col>
          <a-col :xs="24" :md="12" :lg="6" class="mt-4 mt-lg-0">
            <h3 class="type">Graded</h3>
            <p class="text-dark mt-2">
              <span class="font-weight-bold text-success">{{
                statistic.total_graded
              }}</span>
              /
              {{ statistic.total_submitted }}
              Submitted
            </p>
          </a-col>
        </a-row>
        <div class="d-flex flex-column flex-md-row align-items-center">
          <div class="w-100 w-lg-75">
            <a-divider orientation="left">
              <h3>Assignment Grading</h3>
            </a-divider>
          </div>
          <div class="w-auto d-flex flex-column flex-md-row ml-3">
            <template v-if="!isGrading">
              <!-- <a-button
                shape="round"
                icon="select"
                class="mb-3 mb-lg-0 mr-0 mr-md-3"
                >SYNC</a-button
              > -->
              <a-button
                @click.prevent="handleToggleIsGrading"
                shape="round"
                type="primary"
                icon="edit"
                id="btn-input-grade-assignment"
                >INPUT GRADE</a-button
              >
            </template>
            <template v-else>
              <a-button
                @click.prevent="cancelGrading"
                :loading="isLoadingCancelGrading"
                type="danger"
                shape="round"
                icon="close"
                class="mb-3 mb-lg-0 mr-0 mr-md-3"
                >CANCEL</a-button
              >
              <a-button
                @click.prevent="saveGrading"
                :loading="isLoadingSaveGrading"
                shape="round"
                id="btn-save-input-grading"
                icon="save"
                style="background-color: #41b883; color: white"
                >SAVE</a-button
              >
            </template>
          </div>
        </div>
        <a-table
          class="table-scroll mt-4 mt-lg-0"
          id="table-grade-assignment"
          :columns="columns"
          :data-source="dataTable"
          :loading="isLoadingTable"
          :pagination="{ pageSize: 30, hideOnSinglePage: true }"
          bordered
        >
          <div
            class="d-flex justify-content-between align-items-center"
            slot="submit_time"
            slot-scope="text, record"
          >
            <span>{{
              text
                ? moment(text).format("DD MMMM YY, HH:mm")
                : "Waiting to Submit"
            }}</span>
            <a-button
              @click="handleIsDrawerDetailStudentVisible(record)"
              shape="round"
              icon="eye"
              v-if="text"
            ></a-button>
          </div>
          <div
            slot="notes"
            slot-scope="text, record"
            style="text-align: center; font-size: 1.5em"
          >
            <a-icon
              v-if="record.notes"
              style="color: #41b883"
              type="check-circle"
            />
            <div v-else class="circle-undone" />
          </div>
          <div slot="feedback" slot-scope="text, record">
            <template v-if="isGrading">
              <a-input
                size="large"
                :value="text"
                :disabled="!record.submit_time"
                @change="
                  (e) => handleChange(e.target.value, record.key, 'feedback')
                "
              />
            </template>
            <template v-else>
              <span>{{ text }}</span>
            </template>
          </div>
          <div slot="tugas_nilai" slot-scope="text, record">
            <template v-if="isGrading">
              <a-input-number
                size="large"
                :defaultValue="text"
                :min="0"
                :max="100"
                :disabled="!record.submit_time"
                @change="(e) => handleChange(e, record.key, 'tugas_nilai')"
              />
            </template>
            <template v-else>
              <grade-color v-if="text" :grade="Number(text)" />
            </template>
          </div>
        </a-table>
      </div>
    </main-card>
  </div>
</template>

<script>
import moment from 'moment'
import config from '@/config'
import checkIconAssignmentType from '@/helpers/checkIconAssignmentType'
import { renderMathMl } from '@/helpers/mathjax'
const GradeColor = () => import('@/components/app/GradeColor')
const LoadingState = () => import('@/components/app/LoadingState')
const DetailAssignment = () => import('@/components/app/Assignment/DetailAssignment')
const MainCard = () => import('@/components/app/shared/mainCard')
const columns = [
  {
    title: 'No',
    dataIndex: 'no',
    align: 'center',
    // width: '5%',
  },
  {
    title: 'Name',
    dataIndex: 'nama',
    // width: '25%',
  },
  // {
  //   title: 'Notes',
  //   dataIndex: 'notes',
  //   // width: '8%',
  //   scopedSlots: { customRender: 'notes' },
  //   align: 'center',
  // },
  {
    title: 'Submit Status',
    dataIndex: 'submit_time',
    width: 220,
    scopedSlots: { customRender: 'submit_time' },
  },
  {
    title: 'Feedback',
    dataIndex: 'feedback',
    // width: '32%',
    width: 300,
    scopedSlots: { customRender: 'feedback' },
  },
  {
    title: 'Grade',
    dataIndex: 'tugas_nilai',
    width: 150,
    scopedSlots: { customRender: 'tugas_nilai' },
    align: 'center',
  },
]
export default {
  components: {
    GradeColor,
    LoadingState,
    MainCard,
    DetailAssignment,
  },
  data() {
    return {
      columns,
      dataDrawer: {
        key: null,
        no: null,
        nama: null,
        notes: null,
        submit_time: null,
        feedback: null,
        tugas_nilai: null,
        jawaban_murid: null,
        tugas_filepath: null,
        tugas_filename: null,
      },
      dataTable: [],
      header: {
        kelas: null,
        mata_pelajaran: null,
        week: null,
        total_week: null,
        tipe_assignment: null,
        tugas_deadline: null,
        topik: null,
        tugas_detail: {
          nama: null,
          daftar_soal: [],
        },
      },
      statistic: {
        waiting_submit: null,
        total_submitted: null,
        total_student: null,
        total_graded: null,
      },
      isLoadingPage: false,
      isLoadingTable: false,
      isGrading: false,
      isLoadingCancelGrading: false,
      isLoadingSaveGrading: false,
      isModalDetailAssignmentVisible: false,
      isDrawerDetailStudentVisible: false,
      moment,
    }
  },
  filters: {
    filename(value) {
      return value.split('/')[2]
    },
  },
  methods: {
    checkIconAssignmentType,
    handleChange(value, key, column) {
      const target = this.dataTable.find(data => data.key === key)
      if (target) {
        target[column] = value
      }
    },
    downloadFile(filename) {
      const href = `${config.apiUrl}/${filename}`
      window.open(href, '_blank')
    },
    cekAnswer(soal, jawaban) {
      jawaban = JSON.parse(jawaban)
      let correct = 0
      let inCorrect = 0
      let notAnswer = 0
      for (let i = 0; i < soal.length; i++) {
        if (jawaban[i]) {
          if (soal[i].jawaban === jawaban[i]) {
            ++correct
          } else {
            ++inCorrect
          }
        } else {
          ++notAnswer
        }
      }
      return {
        correct,
        inCorrect,
        notAnswer,
      }
    },
    handleIsDrawerDetailStudentVisible(data = null) {
      this.isDrawerDetailStudentVisible = !this.isDrawerDetailStudentVisible

      if (!this.isDrawerDetailStudentVisible) {
        this.dataDrawer = {
          key: null,
          no: null,
          nama: null,
          notes: null,
          submit_time: null,
          feedback: null,
          tugas_nilai: null,
          jawaban_murid: null,
          tugas_filepath: null,
          tugas_filename: null,
        }
      }

      if (data) {
        this.dataDrawer = data
      }
    },
    handleIsModalDetailAssignmentVisible() {
      this.isModalDetailAssignmentVisible = !this.isModalDetailAssignmentVisible
    },
    handleToggleIsGrading() {
      this.isGrading = !this.isGrading
    },
    cancelGrading() {
      this.handleToggleIsGrading()
      this.fetchDataDetailHeader()
    },
    saveGrading() {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to save this assignment grading?</div>
        ),
        onOk: () => {
          this.isLoadingSaveGrading = true
          this.$store.dispatch('slot/PUT_GRADING', {
            idSesi: this.$route.params.id,
            data: this.dataTable,
            idKd: this.header.id_kd,
          })
            .then(isSuccess => {
              this.isLoadingSaveGrading = false
              this.handleToggleIsGrading()
              this.fetchDataDetailHeader()
              if (isSuccess) {
                this.$notification.success({
                  message: 'Successfully update grading',
                })
              } else {
                this.$notification.error({
                  message: 'Sorry.',
                  description: 'Grading can\'t be updated right now. Try again later.',
                })
              }
            })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Save Now',
        cancelText: 'Cancel',
      })
    },
    fetchDataHeader() {
      const idSesi = this.$route.params.id
      this.isLoadingPage = true
      this.$store.dispatch('slot/FETCH_HEADER_ASSIGNMENT_GRADING', { idSesi })
        .then(res => {
          this.header = res
          this.fetchDataDetailHeader()
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isLoadingPage = false
        })
    },
    fetchDataDetailHeader() {
      const idSesi = this.$route.params.id
      this.isLoadingTable = true
      this.$store.dispatch('slot/FETCH_DETAIL_ASSIGNMENT_GRADING', { idSesi })
        .then(res => {
          this.isLoadingTable = false
          this.statistic = res.statistic
          this.dataTable = res.dataTable
        })
    },
  },
  mounted() {
    if (!this.$route.params.id) {
      this.$router.push({ name: '404' })
    } else {
      this.fetchDataHeader()
    }

    let arrayOfRef = []
    const jawaban = this.$refs.jawaban
    if (jawaban) {
      if (jawaban) {
        if (Array.isArray(jawaban)) arrayOfRef = arrayOfRef.concat(jawaban)
        else arrayOfRef.push(jawaban)
      }

      renderMathMl(arrayOfRef)
    }
  },
}
</script>

<style lang="scss">
.circle-undone {
  width: 1em;
  height: 1em;
  border: 2px solid #707788;
  border-radius: 100%;
  margin: 0 auto;
}
</style>
